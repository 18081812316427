









import {Component, Vue} from 'vue-property-decorator';
import {namespace} from "vuex-class";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import CBSRService from "@/services/request/CBSRService";
import CBSR_DTO from "@/dto/request/cbsr/CBSR_DTO";

const AppModule = namespace("App");

@Component({
  components: {SingleFileHolder}
})
export default class CustomerCBSR extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  private newFile: FileMetaDTO | null = null;

  private request: CBSR_DTO | null = null;

  mounted() {
    this.loadRequest();
  }

  loadRequest() {
    this.startLoading();
    CBSRService.getPublic(this.id).then(
        response => {
          this.request = response.data;
          this.request?.documents.sort((a, b) => a.id - b.id);
          this.stopLoading();
        },
        error => {
          this.stopLoading();
          console.log(JSON.stringify(error));
        }
    );
  }

  get id() {
    return this.$route.params.id;
  }

  onNewFileUploaded(file: FileMetaDTO) {
    this.startLoading();
    CBSRService.uploadCBSRDocument(this.id, file.id).then(
        response => {
          this.$modal.hideAll();
          this.newFile = null;
          this.loadRequest();
        },
        error => {
          console.log(JSON.stringify(error))
          this.stopLoading();
        }
    );
  }


}
